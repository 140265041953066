<template>
  <div class="resize">
    <div class="l-col">
      <label class="typo__label standart-text">{{ $t('disk.label') }}</label>
      <base-input
        v-model="formData.diskName"
        :placeholder="$t('disk.placeholder')"
        class="form__field--input paragraf-up"
        @input="onChange"
      >
      </base-input>
    </div>
    <div class="l-col">
      <label class="typo__label standart-text">{{ $t('label.image') }}</label>
      <base-input :value="name" class="form__field--input paragraf-up" :readonly="true">
      </base-input>
    </div>
    <div class="resize-row">
      <div class="resize-row-text">
        <label class="typo__label standart-text">{{ $t('disk.size') }}</label>
      </div>
      <div class="resize-row-input">
        <InputNumber
          v-model="formData.size"
          show-buttons
          mode="decimal"
          suffix=" ГБ"
          :min="size"
          :max="quota"
          button-layout="horizontal"
          :input-style="{ 'text-align': 'center', 'max-width': '4rem' }"
          decrement-button-class="p-button-blue"
          increment-button-class="p-button-blue"
          increment-button-icon="pi pi-plus"
          decrement-button-icon="pi pi-minus"
          aria-describedby="value2-help"
          @input="onChange('size', $event)"
        />
      </div>
    </div>
    <div class="resize-row">
      <div v-if="formData.size < size || formData.size > quota" :style="{ 'text-align': 'center' }">
        <label class="typo__label section-header standart-text error-color">{{
          size > formData.size ? $t('quotaDown') : $t('quotaUp')
        }}</label>
      </div>
      <div v-else>
        <label class="typo__label section-header standart-text">
          {{ $t('cost', { msg: cost.toFixed(2) }) }}
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import setFocus from '@/mixins/setFocus';
import BaseSelect from '@/components/Select/BaseSelect';
import BaseInput from '@/components/BaseInput/BaseInput.vue';
import InputNumber from 'primevue/inputnumber';
import { dev } from '@/layouts/LoginPage/mixins/currentProvider';
export default {
  name: 'NewDiskFromSnap',
  components: { InputNumber, BaseInput },
  mixins: [setFocus],
  props: {
    quota: {
      type: Number,
      default: 0,
      require: true,
    },
    size: {
      type: Number,
      default: 0,
      require: true,
    },
    instance: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      value: '',
      type: '',
      image: [],
      formData: {
        diskName: '',
        from: 'image',
        size: this.size,
        name: '',
        id: '',
        type: '',
        func: '',
      },
    };
  },
  computed: {
    name() {
      return `${this.instance.name} (${Math.ceil(this.instance.size)} ГБ)`;
    },
    price() {
      const type = this.formData.type;
      return type && this.$store.state.moduleStack.price.length > 0
        ? this.$store.state.moduleStack.price.find(x => x.intname === `volume_${type}`)[dev]
        : 0;
    },
    cost() {
      return this.formData.size && this.price ? this.formData.size * this.price : 0;
    },
  },
  mounted() {
    this.$emit('notready');
    this.typeOfDisk();
  },
  methods: {
    typeOfDisk() {
      this.type = this.$store.state.moduleStack.volumes.find(
        x => x.id === this.instance.diskId
      ).volume_type;
      this.formData.type = this.type;
    },
    onChange(event) {
      Object.assign(this.formData, event);
      if (this.formData.diskName && this.formData.size && this.formData.type) {
        return this.$emit('change', {
          name: this.formData.diskName,
          size: this.formData.size,
          snapshot_id: this.instance.id,
          volume_type: this.formData.type,
          metadata: this.instance.metadata,
        });
      } else return this.$emit('notready');
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "label": {
      "disk": "Источник диска",
      "type": "Тип",
      "description": "Описание",
      "image" : "Снимок:",
      "disks" : "Использовать диск как ресурс",
      "snap": "Использовать снапшот как источник"
    },
    "quotaUp": "Значение превышает максимально разрешенное",
    "quotaDown": "Уменьшать диск нельзя",
    "cost": "Стоимость диска составит %{msg} ₽ / ГБ час",
    "disk": {
      "label": "Имя диска:",
      "placeholder": "Имя вашего диска",
      "description" : "Текстовое описание",
      "snap" : "Использовать снапшот как источник",
      "size": "Размер диска:"
    },
    "sure": {
      "confirm": "Увеличить"
    },
    "text": "После сохранения нельзя будет уменьшить размер диска",
    "notice": "Для вступления в силу - перезагрузите сервер"
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';

.resize {
  text-overflow: unset;
  overflow: visible;

  &-row{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content:space-between;
    margin: 1.5rem 1.5rem;
&-input {
  padding: 1.5rem 1.5rem;
}
  }
}
.paragraf {
  margin-bottom: 10px;
  &-up{
    margin-top: 10px;

  }
}
</style>
