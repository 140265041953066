<template>
  <div class="stack-context-menu">
    <template v-if="menu.length && menu[0].length">
      <div v-for="(group, i) in menu" :key="i" class="stack-context-menu__group">
        <component
          :is="item.attrs ? 'a' : 'div'"
          v-for="item in group"
          :key="item.key"
          v-bind="item.attrs"
          class="stack-context-menu__item standart-title"
          :class="{ [`stack-context-menu__item--${item.color}`]: !!item.color }"
          :disabled="item.disabled"
          @click="onClick(item)"
        >
          <plain-button
            v-if="(quota < tariff.size || quotaSnap === 0) && item.key === 'create'"
            v-tooltip="{
              content: text,
              placement: 'auto',
              container: false,
              autoHide: false,
              trigger: 'click hover',
            }"
            icon="error"
            size="medium"
            color="del"
            tabindex="-1"
            class="stack-context-menu__item-btn"
          />
          {{ $t(`actions.${item.key}`) }}
        </component>
      </div>
    </template>
    <template v-else>
      <div class="stack-context-menu__noact standart-text">
        <div v-if="isSuspended" class="standart-title">
          {{ $t('noact.suspended') }}
        </div>
        <div>{{ $t('noact.noactions') }}</div>
      </div>
    </template>
  </div>
</template>

<script>
import serialize from '@/utils/serialize';
import BaseLoader from '@/components/BaseLoader/BaseLoader';
import newDiskFromSnap from '@/mixins/newDiskFromSnap';
import renameSnap from '@//mixins/renameSnap';
import { dev } from '@/layouts/LoginPage/mixins/currentProvider';
import changeSnapDescription from '@/mixins/changeSnapDescription';
export default {
  name: 'SnapContextMenu',
  mixins: [newDiskFromSnap, renameSnap, changeSnapDescription],
  props: {
    tariff: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      res: '',
      oldState: this.tariff.hourly_can,
      timerId: '',
      timerCnt: 0,
      isProcessing: false,
    };
  },
  computed: {
    isColorCreate() {
      return this.quota < this.tariff.size || this.quotaSnap === 0 ? 'warn' : '';
    },
    quotaSnap() {
      return this.$store.getters['moduleStack/aviableSnapshots'];
    },
    text() {
      // Продолжить от сюда!!!
      if ((this.quota < 1 || this.quota < this.tariff.size) && this.quotaSnap > 0) {
        return `Вы достигли квоты по объему сетевых дисков.<br /><br />

Перейдите в раздел <strong><a href="${localStorage.getItem('urlHash')}stack/${
          this.bmId
        }/quotas">Квоты</a></strong> для увеличения. `;
      } else if (this.quotaSnap === 0 && this.quota > this.tariff.size) {
        return `Вы достигли квоты по количеству снапшотов диска. <br /><br />

Перейдите в раздел <strong><a href="${localStorage.getItem('urlHash')}stack/${
          this.bmId
        }/quotas">Квоты</a></strong> для увеличения. `;
      } else
        return `Вы достигли квоты по количеству снапшотов диска и объему сетевых дисков.<br /><br />

Перейдите в раздел <strong><a href="${localStorage.getItem('urlHash')}stack/${
          this.bmId
        }/quotas">Квоты</a></strong> для увеличения. `;
    },
    serverState() {
      return this.tariff.status;
    },
    type() {
      return this.$store.state.moduleStack.volumes.find(x => x.id === this.tariff.volume_id)
        .volume_type;
    },
    price() {
      return this.$store.state.moduleStack.price.length > 0
        ? this.$store.state.moduleStack.price.find(x => x.intname === `snapshot_${this.type}`)[dev]
        : null;
    },
    cost() {
      return this.price ? this.price : 0;
    },
    status() {
      return this.tariff.status.code;
    },
    projectId() {
      return this.$store.getters['moduleStack/project_id'];
    },
    id() {
      return this.tariff.id;
    },
    diskName() {
      return this.disks.find(x => x.id === this.tariff.volume_id).name || this.tariff.volume_id;
    },
    processingStatus() {
      return 2;
    },
    isSuspended() {
      return this.tariff.abusesuspend === 'on' || this.tariff.employeesuspend === 'on';
    },
    menu() {
      const status = this.serverState.code;
      const arr = [];
      arr.push([
        {
          key: 'rename',
          handler: 'rename',
        },
        {
          key: 'descr',
          handler: 'descr',
        },
        {
          key: 'create',
          color: this.isColorCreate,
          handler: 'create',
          action: {
            size: Math.ceil(this.tariff.size),
            name: this.tariff.name,
            diskId: this.tariff.volume_id,
            id: this.tariff.id,
            metadata: this.tariff.metadata,
          },
        },
        {
          key: 'delete',
          action: 'delete',
          color: 'del',
          handler: 'delete',
        },
      ]);
      return arr;
    },
  },
  mounted() {
    if (this.processingStatus === 1) this.updateStatus();
  },
  beforeDestroy() {
    if (this.timerId) clearTimeout(this.timerId);
  },
  methods: {
    onClick(item) {
      if (item.disabled) return;
      if (item.action && item.handler === 'delete') this.deleteSnap(item);
      if (item.action && item.handler === 'create') {
        if (this.quotaSnap > 0 && this.quota > this.tariff.size) {
          this.newDiskFromSnap(item.action);
        } else if (this.quotaSnap === 0 && this.quota > this.tariff.size) {
          this.showError(
            'Вы достигли квоты по количеству снапшотов диска. Перейдите в раздел "Квоты" для увеличения.'
          );
        } else if (this.quota < this.tariff.size && this.quotaSnap > 0) {
          this.showError(
            'Вы достигли квоты по объему сетевых дисков. Перейдите в раздел "Квоты" для увеличения.'
          );
        } else
          this.showError(
            'Вы достигли квоты по количеству снапшотов диска и объему сетевых дисков. Перейдите в раздел "Квоты" для увеличения.'
          );
      }
      if (item.handler === 'rename') this.renameSnap(this.tariff);
      if (item.handler === 'descr') this.changeSnapDescription(this.tariff);
    },
    deleteSnap() {
      const that = this;
      let self;
      const selfName = 'ServerAction';
      let res = '';
      let time;
      this.$modals.open({
        name: selfName,
        html: true,
        text: this.$t('delete', {
          name: this.tariff.name,
          id: this.tariff.id,
          disk: this.diskName,
          cost: this.$n(this.cost * this.tariff.size, 'currency', this.$i18n.locale),
        }),
        onOpen(inst) {
          self = inst;
        },
        footer: {
          confirm: {
            props: {
              title: this.$t('modal.sure.confirm'),
              color: 'error',
            },
            on: {
              click: () => {
                self.text = null;
                self.component = BaseLoader;
                self.closable = false;
                self.footer = false;
                time = Date.now();
                this.isProcessing = true;
                this.deleteSnapshot()
                  .catch(() => (res = 'fail'))
                  .finally(() => {
                    const delay = Date.now() - time < 1000 ? 1000 : 0;
                    setTimeout(() => {
                      self.component = null;
                      self.closable = true;
                      // self.text = this.$t(`modal.sure.${res}`);
                      self.text = this.$t('modal.sure.success');
                      self.footer = {
                        centered: true,
                        confirm: {
                          props: { title: this.$t('modal.sure.close') },
                          on: {
                            click: () => {
                              this.$modals.close();
                              this.$store.dispatch(
                                'moduleStack/fetchSnapshotsServer',
                                this.projectId
                              );
                              this.$store.dispatch('moduleStack/fetchQuotasVolume', this.project);
                            },
                          },
                        },
                      };
                    }, delay);
                  });
              },
            },
          },
          cancel: {
            on: {
              click: () => {
                that.$modals.close();
              },
            },
          },
        },
      });
    },
    handleBillMgrTool(item) {
      if (item.tool.type === 'window') this.goToWindow(item);
      else if (item.tool.type === 'group') this.sendGroupTool(item);
      else throw new Error(`Handler for tool type ${item.tool.type} is not isset`);
    },
    goToWindow(item) {
      this.$store
        .dispatch('moduleStack/fetchBillMgrToolAction', {
          func: item.tool.func,
          elid: this.tariff.id,
        })
        .then(data => {
          if (data && data.ok && data.ok.type === 'url' && data.ok.v) {
            let payload = item.payload ? serialize(item.payload) : '';
            window.open(data.ok.v + payload);
          }
        });
    },
    sendServerAction(params) {
      return this.$store.dispatch('moduleStack/setServerAction', {
        id: this.tariff.id,
        params,
      });
    },
    deleteSnapshot() {
      return this.$store.dispatch('moduleStack/deleteSnapshot', {
        project: this.projectId,
        snap: this.tariff.id,
      });
    },
    async updateServerInfo(callback) {
      this.timerId = setTimeout(() => {
        this.$store
          .dispatch('moduleStack/updateList', { id: this.id, silent: true })
          .then(async data => {
            if (['REBOOT', 'HARD_REBOOT'].includes(data)) {
              this.res = data;
              await this.updateServerInfo(callback);
            } else {
              // console.log(data);
              this.timerCnt = 0;
              this.isProcessing = false;
              clearTimeout(this.timerId);
              this.res = data;
              return 'success';
            }
          });
      }, 1000 * this.timerCnt);
    },
    async updateStatus() {
      this.timerId = setTimeout(() => {
        this.$store
          .dispatch('moduleStack/updateList', { id: this.id, silent: true })
          .then(async data => {
            if (['REBOOT', 'HARD_REBOOT'].includes(data)) {
              if (this.timerCnt < 5) this.timerCnt += 1;
              this.res = data;
              await this.updateStatus();
            } else {
              this.res = data;
              this.timerCnt = 0;
              this.isProcessing = false;
              this.oldState = '';
              clearTimeout(this.timerId);
            }
          });
      }, 1000 * this.timerCnt);
      return this.res;
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "delete": "Удалить снапшот: <b>%{name}</b> (id:%{id}) диска <b>%{disk}</b>? <br /> <br /> Расходы уменьшатся на <b>%{cost}</b> в час.",
    "actions": {
      "delete": "Удалить снапшот",
      "rename": "Переименовать снапшот",
      "descr": "Изменить описание",
      "create": "Создать диск"
    },
    "prolong": {
      "confirm" : "Сменить",
      "order": "Отмена",
      "title": "Увеличить размер диска"
    },
    "noact": {
      "noactions": "Нет доступных действий.",
      "suspended": "Услуга остановлена администратором."
    },
    "sure": {
      "confirm": "Подтвердить"
    },
    "modal": {
      "sure": {
        "text": "Вы собираетесь %{msg} сервер. Вы уверены?",
        "confirm": "Подтвердить",
        "close": "Закрыть",
        "success": "Запрос выполняется. Пожалуйста, дождитесь изменения статуса.",
        "fail": "Кажется, что-то пошло не так. Пожалуйста, попробуйте позже."
      }
    },
    "description": {
      "name": "Описание снапшота:",
      "text" : "Текстовое описание",
      "confirm": "Подтвердить"
    }
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';

$vcm-colors = {
  primary: $primary-color,
  success: $success-color,
  del: $color-red.light,
  warn: $color-light.medium,
}
$vcm-colors-hover = {
  primary: $color-blue.medium,
  success: darken($success-color, 20%),
  del: darken($color-red.light, 20%),
}

.stack-context-menu {

  &__group {
    & + & {
      margin-top: 2.5rem;

      +breakpoint(sm-and-up) {
        margin-top: (1.25rem * 3);
      }
    }
  }
  &__item {
    display: flex;
    color: var(--text);
    text-decoration: none;
    cursor: pointer;
    transition: color 0.3s ease;

    &:first-letter {
      text-transform: uppercase;
    }

    &:focus {
      outline: none;
    }
    &:hover {
      color: $primary-color;
    }

    &[disabled] {
      pointer-events: none;
      cursor: default;
      opacity: 0.6;
    }

    & + & {
      margin-top: 1.25rem;
    }

    for $key, $value in $vcm-colors {
      &--{$key} {
        color: $value;
      }
    }
    for $key, $value in $vcm-colors-hover {
      &--{$key}:hover {
        color: $value;
      }
    }

  }

  &__noact {
    div + div {
      margin-top: 0.75rem;
    }
  }
}
</style>
<style lang="stylus">
@require '~@/assets/styles/vars/variables';

.stack-context-menu {
  .action-popup & {
    padding: 1.5rem 1rem;
    width: calc(100vw - 0.75rem);

    +breakpoint(sm-and-up) {
      padding: 1.5rem;
      width: 260px;
    }
  }
}
</style>
