<template>
  <div class="resize">
    <div class="l-col">
      <label class="typo__label standart-text">{{ $t('disk.name') }}</label>
      <base-input v-model="newNameDisk" class="form__field--input paragraf-up" @input="onChange()">
      </base-input>
    </div>
  </div>
</template>

<script>
import setFocus from '@/mixins/setFocus';
import BaseInput from '@/components/BaseInput/BaseInput.vue';
export default {
  name: 'RenameDisk',
  components: { BaseInput },
  mixins: [setFocus],
  props: {
    name: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      newNameDisk: '',
    };
  },
  computed: {},
  mounted() {
    this.newNameDisk = this.name;
  },
  methods: {
    onChange() {
      this.$emit('change', this.newNameDisk === '' ? 'emptyName' : this.newNameDisk);
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "disk": {
      "name": "Название снапшота:",
      "description" : "Текстовое описание"
    },
    "sure": {
      "confirm": "Подтвердить"
    }
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.resize {
  text-overflow: unset;
  overflow: visible;
}
.paragraf {
  margin-bottom: 10px;
  &-up{
    margin-top: 10px;

  }
}
</style>
